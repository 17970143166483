<template>
  <form class="w-100" @submit.prevent="submit">
    <div class="row">
      <div class="col-12">
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-12">
              <div>
                <h2 class="fw-bolder text-blue">Editar grupo</h2>
              </div>
              <div>
                <div class="card p-5">
                  <div class="w-100 px-4 d-block">
                    <label class="text-gray" for="nombre">Nombre</label>
                    <input
                      class="bg-gray-input border-0 p-5 w-100 my-2 rounded-3"
                      type="text"
                      placeholder="Ingresa nombre del grupo"
                      v-model="model.nombre"
                      id="nombre"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="nombre" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-sm-12">
              <div class="py-5">
                <div class="card p-5 overflow-auto" style="height: 500px">
                  <div class="border-bottom pb-3">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h3 class="text-blue">Agregar invitados</h3>
                      <div class="d-flex align-items-center">
                        <i class="bi bi-search"></i>
                        <input
                          type="text"
                          placeholder="Buscar"
                          class="form-control mx-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center my-4"
                    v-for="(user, e) in usuarios"
                    :key="e"
                  >
                    <div class="d-flex align-items-center">
                      <img
                        src="media/avatars/150-12.jpg"
                        class="rounded-circle"
                        alt=""
                        style="width: 40px"
                      />
                      <p class="mx-3 text-gray">{{ user.nombre }}</p>
                    </div>
                    <div>
                      <input
                        :value="user._id"
                        class="form-check-input"
                        type="checkbox"
                        v-model="userId"
                        id="userId"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-7 col-sm-12">
              <div class="py-5">
                <div class="card p-5 overflow-auto" style="height: 500px">
                  <div class="border-bottom pb-3">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h3 class="text-blue">Agregar ubicaciones</h3>
                      <div class="d-flex align-items-center">
                        <i class="bi bi-search"></i>
                        <input
                          type="text"
                          placeholder="Buscar"
                          class="form-control mx-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center my-4"
                    v-for="(sede, o) in sedes"
                    :key="o"
                  >
                    <div class="d-flex align-items-center">
                      <img
                        src="media/avatars/blank.png"
                        class="rounded-circle"
                        alt=""
                        style="width: 40px"
                      />
                      <p class="mx-3 text-gray">{{ sede.nombre }}</p>
                    </div>
                    <div>
                      <input
                        :value="sede._id"
                        class="form-check-input"
                        type="checkbox"
                        v-model="sedeId"
                        id="sedeId"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex justify-content-center align-items-center">
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn bg-blue text-white rounded-pill my-2 d-block w-100"
                    >
                      Agregar grupo
                    </button>
                    <button
                      type="submit"
                      class="btn bg-white text-blue rounded-pill my-2 d-block w-100"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import { useField, useForm, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2";
import miGrupoService from "@/services/miGrupo.service";
import infoSedes from "@/services/sedes.service";

export default defineComponent({
  name: "mi-grupo-editar-grupo",
  components: {
    ErrorMessage,
  },
  setup() {
    const validationSchema = Yup.object().shape({
      nombre: Yup.string().required(
        "Escribe el nombre del grupo que deseas crear"
      ),
    });
    const {
      handleReset,
      handleSubmit,
      values: model,
    } = useForm({
      validationSchema,
    });
    const sedeId = ref([]);
    const userId = ref([]);
    useField("nombre", null, { initialValue: "" });
    const submit = handleSubmit((values) => {
      values.sedeIds = sedeId.value.join(",");
      values.userIds = userId.value.join(",");
      miGrupoService
        .crearGrupos(values)
        .then((data) => {
          console.log(data);
          handleReset();
          Swal.fire("Guardado", "Tu grupo creado con exito", "success");
        })
        .catch((err) => {
          console.log(err.response.data.message);
          Swal.fire("Error", err.response.data.message, "error");
        });
    });
    const usuarios = ref([]);
    const traerUsuarios = () => {
      miGrupoService.listarUsuarios().then((response) => {
        console.log(response);
        usuarios.value = response.data.data;
      });
    };
    const sedes = ref([]);
    const traerSedes = () => {
      infoSedes.listarSedes().then((result) => {
        console.log(result);
        sedes.value = result.data;
      });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Agregar grupo", ["Pages", "Mis grupos"]);
      traerUsuarios();
      traerSedes();
    });
    return { submit, model, usuarios, sedes, sedeId, userId };
  },
});
</script>
<style scoped>
.border-blue {
  border-color: #27aae1 !important;
}
.text-gray {
  color: #999999;
}
.text-blue {
  color: #27aae1;
}
.bg-blue {
  background-color: #27aae1;
}
.bg-gray-input {
  background-color: #f4f8fb;
}
</style>
